import { STOCK, TAG, NEWS } from '../constants/autocomplete_category'

const validateCommonKeyIsExistInList = (list) => list.every((item) => (
  Object.keys(item).includes('id')
    && Object.keys(item).includes('highlightContent')
    && Object.keys(item).includes('displayContent')
    && Object.keys(item).includes('url')
    && Object.keys(item).includes('label')
))

export default {
  props: {
    stockList: {
      type: Array,
      default: () => [],
      validator(value) {
        return (value && value.every((item) => (
          item.category === STOCK
          && Object.keys(item).includes('ticker')
          && Object.keys(item).includes('name')
          && Object.keys(item).includes('localLangName')
          && Object.keys(item).includes('country')
          && Object.keys(item).includes('exchange')
          && validateCommonKeyIsExistInList(value)
        )))
      },
    },
    tagList: {
      type: Array,
      default: () => [],
      validator(value) {
        return (value && value.every((item) => (
          item.category === TAG
          && validateCommonKeyIsExistInList(value)
        )))
      },
    },
    newsList: {
      type: Array,
      default: () => [],
      validator(value) {
        return (value && value.every((item) => (
          item.category === NEWS
          && validateCommonKeyIsExistInList(value)
        )))
      },
    },
    updateCurrentSelectedItem: {
      type: Function,
      default: () => true,
    },
    clickAutocompleteHandler: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      categoryList: { STOCK, TAG, NEWS },
      selectedItem: null,
    }
  },
  computed: {
    allList() {
      return this.stockList
        .concat(this.tagList)
        .concat(this.newsList)
    },
    allListCount() {
      return [
        this.stockList,
        this.tagList,
        this.newsList,
      ].filter((list) => list.length > 0).length
    },
    idList() {
      return this.allList
        .map((item) => item.id)
    },
    selectedId() {
      return this.selectedItem ? this.selectedItem.id : ''
    },
    allListStringify() {
      // 因為 allList 不會 deep watch, 在這裡將 allList stringify 做 deep watch
      return JSON.stringify(this.allList)
    },
  },
  watch: {
    allListStringify() {
      this.selectedItem = this.allList[0]
      this.updateCurrentSelectedItem(this.allList[0])
    },
  },
  methods: {
    findPreviousItemById(id) {
      const index = this.idList.indexOf(id)
      if (index === -1) {
        return this.allList[0]
      }
      const previousIndex = index - 1
      if (previousIndex < 0) {
        return this.allList[this.allList.length - 1]
      }
      return this.allList[previousIndex]
    },
    findNextItemById(id) {
      const index = this.idList.indexOf(id)
      if (index === -1) {
        return this.allList[0]
      }
      const nextIndex = index + 1
      if (nextIndex >= this.idList.length) {
        return this.allList[0]
      }
      return this.allList[nextIndex]
    },
    selectPrev() {
      const previousItem = this.findPreviousItemById(this.selectedId)
      this.selectedItem = previousItem
      this.updateCurrentSelectedItem(previousItem)
    },
    selectNext() {
      const nextItem = this.findNextItemById(this.selectedId)
      this.selectedItem = nextItem
      this.updateCurrentSelectedItem(nextItem)
    },

    clickAutocompleteItemHandler(item) {
      this.selectedItem = item
      this.updateCurrentSelectedItem(item)
      this.clickAutocompleteHandler()
    },
    mouseoverAutocompleteItemHandler(item) {
      this.selectedItem = item
      this.updateCurrentSelectedItem(item)
    },
  },
}
