import axios from 'axios'

/**
 * 取得 autocomplete
 * @link https://www.notion.so/statementdog/Spec-Search-Bar-api-spec-b068022b1ad04dd5b3ce2debcfa4f3e6
 * @param {String} term - 搜尋關鍵字
 * @return {Promise} axios promise
 */
export function requestAutocomplete({ term = '', signal = null }) {
  return axios.get('/api/v1/autocomplete/', {
    params: { q: term },
    signal,
  })
}

/**
 * 取得某個 categroy 下的 autocomplete
 * @link https://www.notion.so/statementdog/Spec-Search-Bar-api-spec-b068022b1ad04dd5b3ce2debcfa4f3e6
 * @param {String} term - 搜尋關鍵字
 * @param {String} category - (optional) [stocks, tags]
 * @return {Promise} axios promise
 */
export function requestAutocompleteByCategory({ category, term = '', signal = null }) {
  return axios.get(`/api/v1/autocomplete/${category}`, {
    params: { q: term },
    signal,
  })
}
